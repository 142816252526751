import * as React from "react"

// UI
import SectionThreeCols, { ThreeColCard } from "~components/SectionThreeCols"
import {
  faSeedling,
  faFlask,
  faTrainTrack,
  faSparkles,
  faHeartCircle,
  faPartyHorn,
} from "@fortawesome/pro-thin-svg-icons"

const CareersValues = (): JSX.Element => {
  return (
    <SectionThreeCols
      title={
        <>
          <span id="LearnMore" className="text-blue-500">
            Our values are our core.
          </span>
          <br className="hidden sm:block" /> They guide how we design, build, and serve our clients.
        </>
      }
      className="mb-16 lg:mb-40"
    >
      <ThreeColCard title="Always learning" icon={faSeedling} data-sal="slide-up">
        <p>
          We can all do better. We strive to improve without getting defensive. As we grow, we “
          <a
            href="https://review.firstround.com/give-away-your-legos-and-other-commandments-for-scaling-startups"
            target="_new"
          >
            give away our legos
          </a>
          ” and empower teammates with ownership and responsibility.
        </p>
      </ThreeColCard>
      <ThreeColCard title="Scientific" icon={faFlask} data-sal="slide-up" data-sal-delay="100">
        <p>
          The important thing is to begin, get feedback, and iterate. We define a problem, hypothesize a cause or
          solution, and conduct experiments to test each hypothesis.
        </p>
      </ThreeColCard>
      <ThreeColCard title="Empathetic" icon={faHeartCircle} data-sal="slide-up" data-sal-delay="200">
        <p>
          We think about how the actions we take affect our clients and their customers. We check in on teammates and
          make room for different or contrasting viewpoints.
        </p>
      </ThreeColCard>
      <ThreeColCard title="Transparent &amp; Kind" icon={faSparkles} data-sal="slide-up">
        <p>
          We hold that it is better to be awkward and honest about our mistakes, and to give and receive empathy, rather
          than take another course. We strive to be open, even redundant.
        </p>
      </ThreeColCard>
      <ThreeColCard title="Celebratory" icon={faPartyHorn} data-sal="slide-up" data-sal-delay="100">
        <p>
          Creativity is our fuel. One of the biggest ways we replenish it is by finding and celebrating things that are
          working well that otherwise might not have been noticed.
        </p>
      </ThreeColCard>
      <ThreeColCard title="Disciplined" icon={faTrainTrack} data-sal="slide-up" data-sal-delay="200">
        <p>
          We start each day/week/quarter/year with an intention. We follow through, follow up, and invest in ourselves.
          We keep commitments.
        </p>
      </ThreeColCard>
    </SectionThreeCols>
  )
}

export default CareersValues
