import * as React from "react"

// UI
import Section from "~components/Section"
import SectionAlt from "~components/SectionAlt"
import SectionThreeCols from "~components/SectionThreeCols"
import Spirograph from "~components/artwork/Spirograph"

// Media
import Headline from "~ui/Headline"

const InterviewCard = ({
  animationDelay = 0,
  body,
  borderColor = "border-yellow-900 dark:border-yellow-300",
  number,
  title,
}: InterviewCardProps): JSX.Element => {
  return (
    <div
      className="bg-black dark:bg-white rounded-3xl p-8 items-start justify-start"
      data-sal="slide-up"
      data-sal-delay={animationDelay}
    >
      <div
        className={`border rounded-full py-3 px-12 text-3xl font-bold text-white dark:text-black inline-block mb-12 lg:mb-16 ${borderColor}`}
      >
        {number}
      </div>
      <Headline level={4} colorOverride="text-white dark:text-black" className="mb-6">
        {title}
      </Headline>
      <p className="text-white dark:text-black">{body}</p>
    </div>
  )
}

const CareersInterviews = (): JSX.Element => {
  return (
    <SectionAlt className="relative overflow-hidden mb-12 md:mb-20 lg:mb-32">
      <Spirograph
        shape={2}
        width="w-full2x"
        position="absolute bottom-0 !top-auto"
        offsetX="-translate-x-1/4"
        offsetY="translate-y-3/4 sm:translate-y-1/4 md:translate-y-2/3 xl:translate-y-3/5 2xl:translate-y-1/4"
        variant="yellow"
        zIndex="z-0"
      />
      <div className="relative z-1">
        <Section>
          <Headline level={2} data-sal="slide-up">
            Our Interview Process
          </Headline>
          <p className="md:text-lg" data-sal="slide-up" data-sal-delay="200">
            At Canopy, we want to ensure that you have a great end-to-end interview experience, complete with
            challenging and thought provoking interviews, and a personalized experience with our recruiters. Not only
            will we get to know you, but you&#39;ll get to meet your potential colleagues and work through realistic
            problems together, like you would day-to-day as a Canopian.
          </p>
        </Section>
        <SectionThreeCols className="mb-4 mt-12 lg:mt-16">
          <InterviewCard
            number="1"
            title="Intro Call"
            body="We&#39;ll schedule a 15-30 minute virtual chat with a People Team member to get to know you, discuss your work history and career goals, answer your questions, and walk you through the full interview process. We want you to succeed!"
            borderColor="border-orange-900 dark:border-orange-200"
          />
          <InterviewCard
            number="2"
            title="Meet the Hiring Manager"
            body="During this 30-60 minute video interview with your potential manager, you&#39;ll have an opportunity to dive deeper into your skillset and demonstrate your enthusiasm for our Company Values."
            animationDelay={150}
          />
          <InterviewCard
            number="3"
            title="Meet the Canopy Team"
            body="In this stage, you&#39;ll have several cross-functional interviews to work on real-life challenges and better understand our company values and culture. These interviews may be 2:1 or multiple 1:1s and last for 30-60 minutes each."
            borderColor="border-green-900 dark:border-green-200"
            animationDelay={300}
          />
        </SectionThreeCols>
      </div>
    </SectionAlt>
  )
}

interface InterviewCardProps {
  animationDelay?: number
  body: string
  borderColor?: string
  number: string
  title: string
}

export default CareersInterviews
