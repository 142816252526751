import * as React from "react"

// UI
import SectionTwoCols, { TwoColCard } from "~components/SectionTwoCols"
import Headline from "~ui/Headline"

// Media
import awardImg from "~images/careers/careers-awards.png"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/pro-light-svg-icons"

const CareersAwards = (): JSX.Element => {
  return (
    <SectionTwoCols className="mb-20 md:mb-24 lg:mb-40 sm:mt-8 md:mt-16 lg:mt-24">
      <TwoColCard data-sal="slide-up">
        <Headline level={2} className="mb-6 md:mb-8">
          Our Culture Awards
        </Headline>
        <p className="md:text-lg mb-8">
          We wouldn&#39;t have a company without our amazing employees. We&#39;re thrilled to have earned these culture
          awards!
        </p>
        <Link
          to="https://www.comparably.com/companies/canopy-servicing"
          target="_new"
          className="text-blue-500 font-bold lg:text-lg"
        >
          Canopy on Comparably <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
        </Link>
      </TwoColCard>
      <TwoColCard
        className="bg-green-900 py-8 lg:py-12 px-8 lg:px-12"
        data-sal="slide-up"
        data-sal-delay="150"
        noPadding
      >
        <img src={awardImg} alt="Canopy Servicing Culture Awards" data-sal="slide-up" data-sal-delay="300" />
      </TwoColCard>
    </SectionTwoCols>
  )
}

export default CareersAwards
