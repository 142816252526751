import * as React from "react"

// UI
import Section from "~components/Section"
import Headline from "~ui/Headline"

const CareersDiversity = (): JSX.Element => {
  return (
    <Section className="mb-16 md:mb-20 lg:mb-32">
      <Headline level={5} colorOverride="text-blue-500" className="mb-2 lg:mb-4">
        Diversity, Equity, and Inclusion
      </Headline>
      <Headline level={2} className="mb-8 md:mb-12" data-sal="slide-up" data-sal-delay="200">
        We are committed
        <br /> to doing <span className="border-b-8 border-orange-600">better</span>
      </Headline>
      <p className="md:text-lg leading-normal" data-sal="slide-up" data-sal-delay="200">
        Our accelerated growth is the result of our diverse backgrounds — our differing experiences of
        affluency/poverty, our physical, racial, ethnic, and neurological diversity, and the many perspectives we bring
        to the table based on our unique individual and family histories.
        <br />
        <br />
        As we grow, Canopy evaluates new hires based on their skills, domain knowledge, and experience. We are looking
        for team members who will move our company forward and who share our values and commitment to diversity and
        inclusion.
        <br />
        <br />
        This means we seek employees who will share their perspectives, even when those views are different from the
        ideas and beliefs of other members of the team, and we challenge ourselves to live with the discomfort that can
        result from honest interaction.
        <br />
        <br />
        At Canopy, it is better to be awkward, honest, and empathetic than to be right. We believe we can all be better,
        and we strive to do that every day. This applies to building our product, supporting our clients, and making
        diversity and inclusion the default rather than the exception.
      </p>
    </Section>
  )
}

export default CareersDiversity
