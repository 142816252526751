import * as React from "react"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import {
  faBabyCarriage,
  faCupTogo,
  faHouseLaptop,
  faIslandTropical,
  faLampDesk,
  faMoneyBillWave,
  faPiggyBank,
  faPlane,
  faSpa,
  faSparkles,
  faStethoscope,
  faUsers,
} from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// UI
import Section from "~components/Section"
import SectionAlt from "~components/SectionAlt"
import Headline from "~ui/Headline"

const BenefitCard = ({
  animationDelay = 0,
  borderColor = "border-green-900",
  className,
  icon = faSparkles,
  title,
  type = "default",
}: BenefitCardProps): JSX.Element => {
  const TallCardClasses = "lg:row-span-2 justify-center text-center flex-col"
  const WideCardClasses = "lg:col-span-2"

  return (
    <div
      className={`flex gap-6 rounded-3xl border p-6 2xl:p-8 items-center ${borderColor} ${
        type === "tall" ? TallCardClasses : ""
      } ${type === "wide" ? WideCardClasses : ""} ${className}`}
      data-sal="slide-up"
      data-sal-delay={animationDelay}
    >
      <div
        className={`bg-white rounded-full flex-none flex items-center justify-center ${
          type === "tall" ? "h-24 lg:h-32 w-24 lg:w-32" : "h-12 lg:h-16 w-12 lg:w-16"
        }`}
      >
        <FontAwesomeIcon
          icon={icon}
          size={type === "tall" ? "4x" : "2x"}
          className="text-black transform scale-75 lg:scale-100"
        />
      </div>
      <Headline level={5} colorOverride="text-white">
        {title}
      </Headline>
    </div>
  )
}

const CareersBenefits = (): JSX.Element => {
  return (
    <SectionAlt color="dark" className="mb-16 md:mb-20 lg:mb-32">
      <Section>
        <Headline level={2} colorOverride="text-white" className="mb-8 md:mb-16" data-sal="slide-up">
          Why Work at Canopy?
        </Headline>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 2xl:gap-6 mb-4 lg:mb-8">
          <BenefitCard
            title="100% Remote Workplace"
            icon={faHouseLaptop}
            type="tall"
            borderColor="border-yellow-900"
            className="order-first lg:order-none"
          />
          <BenefitCard
            title="Flexible Vacation Time"
            icon={faIslandTropical}
            type="wide"
            borderColor="border-orange-900"
            animationDelay={100}
          />
          <BenefitCard title="Paid Parental Leave" icon={faBabyCarriage} animationDelay={300} />
          <BenefitCard
            title="Medical, Dental, and Vision Insurance"
            icon={faStethoscope}
            type="tall"
            borderColor="border-yellow-900"
            className="order-first lg:order-none"
            animationDelay={200}
          />
          <BenefitCard title="401(k) + Canopy Contribution" icon={faPiggyBank} animationDelay={100} />
          <BenefitCard title="Anniversary Travel Credit" icon={faPlane} animationDelay={200} />
          <BenefitCard
            title="Competitive Salary & Equity"
            icon={faMoneyBillWave}
            type="wide"
            borderColor="border-orange-900"
            animationDelay={100}
          />
          <BenefitCard title="Remote Set Up Credit" icon={faLampDesk} animationDelay={200} />
          <BenefitCard title="Monthly Wellness Credit" icon={faSpa} animationDelay={100} />
          <BenefitCard title="Monthly Food & Snack Credit" icon={faCupTogo} animationDelay={200} />
          <BenefitCard
            title="Insanely Cool Yearly Offsites"
            icon={faUsers}
            borderColor="border-yellow-900"
            animationDelay={300}
          />
        </div>
      </Section>
    </SectionAlt>
  )
}

interface BenefitCardProps {
  animationDelay?: number
  borderColor?: string
  className?: string
  icon?: IconProp
  title: string
  type?: "default" | "tall" | "wide"
}

export default CareersBenefits
