import React from "react"
import { IconProp } from "@fortawesome/fontawesome-svg-core"

// UI
import Headline from "~ui/Headline"
import Card from "~components/Card"
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome"

export const ThreeColCard: React.FC<ColCardProps> = ({
  className,
  title,
  icon,
  children,
  level = 4,
  ...props
}: ColCardProps) => {
  const colCardBaseClasses = "border border-black dark:border-gray-400 "

  return (
    <Card className={colCardBaseClasses + className} justify="start" {...props}>
      <div>
        <Icon icon={icon} size="3x" className="mb-6 lg:mb-8 text-black dark:text-white" />
      </div>
      <Headline level={level} className="mb-3">
        {title}
      </Headline>
      {children}
    </Card>
  )
}

const SectionThreeCols: React.FC<SectionThreeColsProps> = ({
  body,
  children,
  className,
  level = 2,
  narrow = false,
  title,
  titleTextSize,
  ...props
}: SectionThreeColsProps) => {
  const baseContainerClasses = "mx-auto sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-5xl 2xl:max-w-7xl px-6 lg:px-12"
  const narrowContainerClasses = "lg:px-12 2xl:px-8"
  const baseGridClasses = "grid grid-cols-1 gap-4 md:gap-6 pt-8 md:pt-12"
  const defaultGridClasses = "lg:grid-cols-3"
  const narrowGridClasses = "lg:grid-cols-3"

  return (
    <div className={`${baseContainerClasses} ${className}`} {...props}>
      <div className={`px-6 2xl:px-8 ${narrow && narrowContainerClasses}`}>
        {title && (
          <Headline level={level} textSize={titleTextSize} className={narrow && "lg:px-6"} data-sal="slide-up">
            {title}
          </Headline>
        )}
        {body && (
          <p className="lg:text-lg md:mb-6" data-sal="slide-up" data-sal-delay="200">
            {body}
          </p>
        )}
      </div>
      {children && (
        <div className={`${baseGridClasses} ${narrow ? narrowGridClasses : defaultGridClasses}`}>{children}</div>
      )}
    </div>
  )
}

interface ColCardProps {
  children?: string | React.ReactNode
  className?: string
  title: string | React.ReactNode
  icon?: IconProp
  level?: 1 | 2 | 3 | 4 | 5
}

interface SectionThreeColsProps {
  body?: string
  children?: React.ReactNode
  className?: string
  level?: 1 | 2 | 3 | 4 | 5
  narrow?: boolean
  title?: string | React.ReactNode
  titleTextSize?: string
}

export default SectionThreeCols
