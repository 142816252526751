import React from "react"

// UI
import Layout from "../components/layout"
import SEO from "../components/seo"
import CareersHero from "~content/careers/Hero"
import CareersValues from "~content/careers/Values"
import CareersBenefits from "~content/careers/Benefits"
import CareersDiversity from "~content/careers/Diversity"
import CareersInterviews from "~content/careers/Interviews"
import CareersAwards from "~content/careers/Awards"
import AboutCareersCTA from "~content/about/CareersCTA"

const CareersPage: React.FC = () => {
  return (
    <Layout showCta={false}>
      <SEO
        canonicalTag="https://canopyservicing.com/careers/"
        description="Canopy Servicing is an API-first platform that is enabling the next generation of credit and lending products."
        title="Careers | Canopy Servicing"
      />
      <CareersHero />
      <CareersValues />
      <CareersBenefits />
      <CareersDiversity />
      <CareersInterviews />
      <CareersAwards />
      <AboutCareersCTA
        linkTo="https://jobs.lever.co/canopyservicing/"
        buttonText="View Open Positions"
        className="mb-20 lg:mb-24 xl:mb-32"
      />
    </Layout>
  )
}

export default CareersPage
