import React from "react"
import Marquee from "react-fast-marquee"
import scrollTo from "gatsby-plugin-smoothscroll"

// UI
import Button from "~ui/Button"
import SectionHero from "~components/SectionHero"
import { faArrowRight } from "@fortawesome/pro-light-svg-icons"

// Media
import HeroPhoto1 from "~images/careers/hero1.jpg"
import HeroPhoto2 from "~images/careers/hero2.jpg"
import HeroPhoto3 from "~images/careers/hero3.jpg"
import HeroPhoto4 from "~images/careers/hero4.jpg"
import HeroPhoto5 from "~images/careers/hero5.jpg"

const MarqueeItem = (props) => (
  <div className="rounded-3xl overflow-hidden h-48 sm:h-64 lg:h-96 xl:h-96 mx-1 lg:mx-2">
    <img src={props.imgSrc} alt="Photo of Canopy Employee" className="h-full" />
  </div>
)

const CareersHero: React.FC = () => (
  <>
    <SectionHero
      title="Careers at Canopy"
      subtitle="Do the best work of your life at Canopy. We are always looking for humble and creative thinkers to join our incredibly talented team."
      level={1}
      buttons={false}
      titleTextSize="text-3xl lg:text-6xl xl:text-8xl"
    >
      <div className="flex flex-col sm:flex-row mt-8 lg:mt-10" data-sal="slide-up" data-sal-delay="700">
        <Button
          style="primary"
          to="https://jobs.lever.co/canopyservicing/"
          icon={faArrowRight}
          size="large"
          className="mb-2 sm:mb-0 sm:mr-4"
        >
          View Open Positions
        </Button>
        <div onClick={() => scrollTo("#LearnMore")}>
          <Button size="large" className="w-full">
            Learn More
          </Button>
        </div>
      </div>
    </SectionHero>
    <Marquee gradientWidth={120} className="mt-8 lg:mt-0 mb-20 lg:mb-32">
      <MarqueeItem imgSrc={HeroPhoto5} />
      <MarqueeItem imgSrc={HeroPhoto1} />
      <MarqueeItem imgSrc={HeroPhoto2} />
      <MarqueeItem imgSrc={HeroPhoto3} />
      <MarqueeItem imgSrc={HeroPhoto4} />
    </Marquee>
  </>
)

export default CareersHero
