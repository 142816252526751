import React from "react"

const SectionCallout: React.FC<SectionCalloutProps> = ({
  children,
  color = "default",
  className,
  narrow = false,
}: SectionCalloutProps) => {
  return (
    <div className="container mx-auto">
      <div
        className={`py-12 md:py-16 xl:py-20 rounded-3xl mx-6 lg:mx-12 ${color === "default" ? "bg-purple-500" : ""} ${
          color === "dark" ? "bg-black dark:bg-white" : ""
        } ${color === "green" ? "bg-green-900" : ""} ${color === "primary" ? "bg-blue-700" : ""} ${className}`}
      >
        <div className="mx-auto sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-5xl 2xl:max-w-7xl" data-sal="slide-up">
          <div className={narrow ? "sm:px-6 md:px-0 lg:px-12" : "sm:px-6 lg:px-12"}>
            <div className="px-6 2xl:px-8">{children}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

interface SectionCalloutProps {
  children?: React.ReactNode
  color?: "default" | "dark" | "primary" | "green"
  className?: string
  narrow?: boolean
}

export default SectionCallout
