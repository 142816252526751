import * as React from "react"

// UI
import SectionCallout from "~components/SectionCallout"
import Button from "~ui/Button"
import Headline from "~ui/Headline"
import Spirograph from "~components/artwork/Spirograph"
import { faArrowRight } from "@fortawesome/pro-light-svg-icons"

const AboutCareersCTA = ({
  buttonText = "Careers at Canopy",
  className = "mb-0 md:mb-16",
  linkTo = "/careers/",
}: AboutCareersCTAProps): JSX.Element => {
  return (
    <SectionCallout color="primary" className={`relative overflow-hidden text-center ${className}`}>
      <Spirograph
        shape={2}
        width="w-full2x"
        position="absolute -bottom-1/2 !top-auto"
        offsetX="-translate-x-1/4"
        offsetY="-translate-y-3/5 md:-translate-y-2/5"
        variant="alt"
        zIndex="z-0"
      />
      <div className="relative z-1">
        <Headline level={2} className="text-white mb-6" data-sal="slide-up">
          Careers at Canopy
        </Headline>
        <p className="text-white md:text-xl max-w-2xl mx-auto mb-12" data-sal="slide-up" data-sal-delay="150">
          Do you believe financial products should be personal, transparent, and safe? Consider joining our all-star
          team!
        </p>
        <Button
          size="large"
          to={linkTo}
          className="w-full sm:w-auto"
          icon={faArrowRight}
          data-sal="slide-up"
          data-sal-delay="300"
        >
          {buttonText}
        </Button>
      </div>
    </SectionCallout>
  )
}

interface AboutCareersCTAProps {
  buttonText?: string
  className?: string
  linkTo?: string
}

export default AboutCareersCTA
